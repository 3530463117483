import {
    userAgent,
    sc_asa_digi_list2,
    asaPrebidVideoInstremAd,
    sc_asa_digi_prop53,
    sc_asa_digi_prop48,
    sc_asa_digi_prop40,
    asa12_mode,
    ad_kijinaka,
    href,
    cookie,
} from '../constants/globalConstants';

export const getGoogletag = () => {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];
    return window.googletag;
}

// Detect OS
export const getOS = () => {
    var os = '';
    if(userAgent.indexOf('iphone') != -1) {
      os = 'iphone';
    } else if(userAgent.indexOf('android') != -1) {
      os = 'android';
    } else if(userAgent.indexOf('windows nt') != -1) {
      os = 'windows';
    } else if(userAgent.indexOf('mac os x') != -1) {
      os = 'mac';
    } else {
      os = 'other';
    }
    return os;
}

// Detect Browser
export const getBrowser = () => {
    var browser = '';
    if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
      browser = 'ie';
    } else if(userAgent.indexOf('edg') != -1) {
      browser = 'edge';
    } else if(userAgent.indexOf('chrome') != -1) {
      browser = 'chrome';
    } else if(userAgent.indexOf('safari') != -1) {
      browser = 'safari';
    } else if(userAgent.indexOf('firefox') != -1) {
      browser = 'firefox';
    } else {
      browser = 'other';
    }
    return browser;
}

export const getPgTargetValue = ( browser ) => {
    // 動画関連の内容分類（instream用）
    var Pg_Target_movie_array = ['B05000000', 'B05010000', 'B05040000', 'B05050000', 'B05060000', 'B05020000', 'B05070000', 'B05030000'];
    //内容分類取得
    var Pg_Target_value = '';
    var Pg_Target_array = [];
    if( sc_asa_digi_list2 !== "" ) {
        Pg_Target_array = sc_asa_digi_list2.split(":");
        for( var j = 0; j < Pg_Target_array.length; j++ ) {
            if( j === 0 ) {
                Pg_Target_value = Pg_Target_array[j];
            } else {
                Pg_Target_value = Pg_Target_value + "," + Pg_Target_array[j];
            }
            if( browser === 'chrome' || browser === 'edge' ) {
                if( typeof asaPrebidVideoInstremAd === 'undefined' ) {
                    for( var k = 0; k < Pg_Target_movie_array.length; k++ ) {
                        if( Pg_Target_movie_array[k] === Pg_Target_array[j] ) {
                            window.asaPrebidVideoInstremAd = "target";
                            break;
                        }
                    }
                }
            }
        }
    }
    return Pg_Target_value;
}

export const getPgKeywordValue = () => {
    //キーワード取得
    var Pg_Keyword_value = "";
    if ( typeof ( sc_asa_digi_prop53 ) === "undefined" ) {
        Pg_Keyword_value = "";
    } else {
        if ( sc_asa_digi_prop53 !== "" ) {
            var Pg_Keyword_array = sc_asa_digi_prop53.split(",");
            for ( var j = 0; j < Pg_Keyword_array.length; j++ ) {
                Pg_Keyword_array[j] = Pg_Keyword_array[j].replace(/\(/g, '').replace(/\)/g, '').replace(/（/g, '').replace(/）/g, '').replace(/＊/g,'');
                if ( j === 0 ) {
                    Pg_Keyword_value = Pg_Keyword_array[j];
                } else {
                    Pg_Keyword_value = Pg_Keyword_value + "," + Pg_Keyword_array[j];
                }
            }
        }
    }
    return Pg_Keyword_value;
}

export const getPgEnvValue = () => {
    //環境取得 (Plasma:p or 旧朝デジ:s)
    var Pg_Env_value = "";
    if ( typeof ( sc_asa_digi_prop48 ) === "undefined" ) {
        Pg_Env_value = "";
    } else {
        if ( sc_asa_digi_prop48 !== "" ) {
            var Pg_Env_value = sc_asa_digi_prop48;
        }
    }
    return Pg_Env_value;
}

export const getSiteRef = () => {
    //refを取得
    var g_site_ref = '';
    if ( href.indexOf('ref=yahoo') != -1 ) {
        g_site_ref = "yahoo";
    } else if ( href.indexOf('ref=smartnews') != -1 ) {
        g_site_ref = "smartnews";
    } else if ( href.indexOf('ref=line') != -1 ) {
        g_site_ref = "line";
    } else {
        g_site_ref = "";
    }
    return g_site_ref;
}

export const getAoneSegment = () => {
    //セグメント値取得
    var goAcn = "ASAHISEG";
    var goAco = cookie;
    var goApos = goAco.indexOf(goAcn + "=");
    var goAseg = ( goApos != -1) ? goAco.substring( goApos + goAcn.length + 1, ( goAco.indexOf( "; ", goApos ) != -1 ) ? goAco.indexOf( "; ", goApos ) : goAco.length ) : null;
    goAseg = ( goAseg ) ? unescape( goAseg ) : null;
    goAseg = ( goAseg ) ? ( goAseg .substr(-1) != "/") ? goAseg + "/" : goAseg : null;
    goAseg = ( goAseg ) ? goAseg = goAseg.replace(/=/g, " |") : null;
    goAseg = ( goAseg ) ? goAseg = goAseg.replace(/\//g, "| ") : null;
    return goAseg;
}

export const getPgArticleId = () => {
    var Pg_ArticleId = '';
    if ( typeof( sc_asa_digi_prop40 ) === "undefined") {
        Pg_ArticleId = '';
    } else {
        Pg_ArticleId = sc_asa_digi_prop40;
    }
    return Pg_ArticleId
}

export const getAsahiMemberType = () => {
    if ( typeof asa12_mode === "undefined" ) {
        asa12_mode = "0";
    }
    var asa12_mode_v = "0";
    if ( asa12_mode == 0 || asa12_mode == null ) {
        asa12_mode_v = "0";
    } else {
        asa12_mode_v = ( asa12_mode ) ? String( asa12_mode ) : null;
    }
    //朝デジが3コースになり会員情報を細かく識別するため
    var asahi_cookies = cookie.split('; ');
    var asadigi_member_flag = false;
    var asadigi_member_type = '0';
    for( var i = 0; i < asahi_cookies.length; i++ ) {
      var asahi_cookie = asahi_cookies[i];
      if( /WWW_SESS=/.test( asahi_cookie ) ) {
        var asadigi_member_type = asahi_cookie.replace( 'WWW_SESS=', '' ).substr( 0, 25 ).replace( /_/g, '' ).replace( /\d\d\d\d\d\d\d\d/, 'YYYYMMDD');
        asadigi_member_flag = true;
        break;
      }
    }
    return [asa12_mode_v,asadigi_member_type];
}

export const getPPID = () => {
    var asahi_cookies = cookie.split(/; *?/);
    var plasma_uid, plasma_cid;
    for( var i = 0; i < asahi_cookies.length; i++ ) {
        var asahi_cookie = asahi_cookies[i];
        // digital.asahi.com と www.asahi.com の AB テスト対象者に割り振られているクッキー (発行時から1年間有効)
        if( /PLASMA_UID/.test( asahi_cookie ) ) {
            plasma_uid = asahi_cookie.replace( 'PLASMA_UID=', '' ).trim();
            break;
        }
        // www.asahi.com の全アクセスユーザーに割り振られているクッキー (発行時から1年間有効)
        if( /PLASMA_CID/.test( asahi_cookie ) ) {
            plasma_cid = asahi_cookie.replace( 'PLASMA_CID=', '' ).trim() + 'DAAmeRvPSshH';
        }
    }
    return plasma_uid || plasma_cid;
}

export const getAdKijinaka = () => {
    if ( typeof ad_kijinaka === "undefined" ) {
        ad_kijinaka = "";
    }
    return ad_kijinaka;
}

export const getFreeAreaForAd = () => {
    //無料域ならフラグON
    var freeAreaForAd = '';
    if ( href.indexOf( 'www.asahi.com' ) != -1 || href.indexOf( 'wwwcdn.asahi.com' ) != -1 || href.indexOf( 'www3.asahi.com' ) != -1) {
        freeAreaForAd = "ON";
    } else {
        freeAreaForAd = "";
        if (typeof ENABLE_FREE_AREA_AD === 'string' && ENABLE_FREE_AREA_AD === 'ON') {
            freeAreaForAd = 'ON';
        }
    }
    return freeAreaForAd;
}

export const getAsaAdBrowserIE = ( browser ) => {
    // ブラウザがieかどうか判定（EBDA除外用）
    var asaAdBrowserIE = '';
    if ( browser === 'ie' ) {
        asaAdBrowserIE = "1";
    } else {
        asaAdBrowserIE = '';
    }
    return asaAdBrowserIE;
}

export const getArticleKind = () => {
    //記事と写真台座の判別
    var article_kind = '';
    if (href.indexOf("/articles/photo/") !== -1) {
        article_kind = 'pc_daiza';
    } else if (href.indexOf("/articles/") !== -1) {
        article_kind = 'pc_kiji';
    }
    return article_kind;
}

export const getHref = () => {
    return href;
}

export const getUserAgent = () => {
    return userAgent;
}

export const setGateDesign = () => {
    // PC一記事ゲート広告化用実装
    var newStyle = document.createElement('style');
    newStyle.type = 'text/css';
    newStyle.innerText = '#HometopAdInner{padding:0}#gate_left,#gate_right{position:absolute;top:0;z-index:1;width:145px;height:730px}#gate_left{left:0}#gate_right{left:1145px}#Header{border:none;}#Header #GlobalNav{width:1000px;border-bottom:1px solid #5A5A53;}#HometopAdOuter+div{width:1000px;margin:auto}@media screen and (min-width:1291px){#HometopAdOuter{position:relative;width:1290px}#Header{width:1290px;margin:auto;}}@media screen and (max-width:1290px){body{width:1290px}#HometopAdOuter{position:static}}';
    document.getElementsByTagName('HEAD').item(0).appendChild(newStyle); // オリンピックゲート広告用のタグ挿入判定
  
    window.addEventListener('DOMContentLoaded', function (event) {
        document.getElementsByTagName('body')[0].id = 'wrap';
        var board_frame = document.getElementById('HometopAdOuter');
        board_frame.appendChild(newStyle);
        var gate_parts = ['gate_left', 'gate_right'];

        for (var i = 0; i < 2; i++) {
            var gate_part = gate_parts[i];
            var gate_frame = document.createElement('div');
            gate_frame.setAttribute('id', gate_part);
            var gate_display = document.createElement('script'); // gate_display.innerHTML = 'googletag.cmd.push(function(){googletag.display("' + gate_part + '")});'
            gate_frame.appendChild(gate_display);
            board_frame.appendChild(gate_frame);
        }
    });
}

export const setLazyload = (
    inView, opt_div, gamSettingFunc
) => {
    const selector = `#${opt_div}`;
    const checkIsOptDiv = setInterval( () => {
        const targetElem = document.querySelector( selector );
        if ( !targetElem ) { return; }
        console.log( 'setLazyload: find dom', opt_div );
        clearInterval( checkIsOptDiv );
        // ビューに入ってきたら広告を表示する
        inView( selector ).once( 'enter', () => {
          console.log( 'setLazyload: got in view', opt_div );
          gamSettingFunc();
        } );
      }, 100 );        
}

export const getPageFlag = ( content_classes, page_flags ) => {
    // 広告枠の出し分けが必要なページを判定する
    var metaTag = document.head.children;
    var metaLength = metaTag.length;
    var page_class_array = undefined;
    for( var i = 0; i < metaLength; i++ ){
        var proper = metaTag[i].getAttribute( 'name' );
        if( proper === 'cXenseParse:ash-category-id' ){
            var dis = metaTag[i];
            page_class_array = dis.content.split(",");
            break;
        }
    }
    if ( page_class_array ) {
        Object.keys( content_classes ).find( ( key ) => {
          const contentClassesArray = content_classes[key];
          const FLAG_KEY = key.split( '_AREA' )[0];
          console.log( FLAG_KEY );
          page_flags.IS[FLAG_KEY] = page_class_array.some( ( contentClass ) =>
            contentClassesArray.includes( contentClass )
          );
          if ( page_flags.IS[FLAG_KEY] ) {
            return true;
          } else if ( FLAG_KEY === 'DEFAULT' ) {
            page_flags.IS.NORMAL = true;
            return true;
          }
        } );
    } else {
        page_flags.IS.NORMAL = true;
    }
    return page_flags;
}

export const getCookieDeprecationLabel = async () => {
    var cookieDeprecationLabel = undefined;
    if (window.navigator && 'cookieDeprecationLabel' in window.navigator) {
        // Request value and resolve promise
        await window.navigator.cookieDeprecationLabel.getValue().then((label) => {
            cookieDeprecationLabel = label;
        });
    }
    return cookieDeprecationLabel;
}

export const makeGptTag = () => {
    var n = document.querySelector("head"); 
    var r = document.createElement("script");
    r.type = "text/javascript";
    r.setAttribute("async", "async");
    r.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
    n.appendChild(r);
}
