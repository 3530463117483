// グローバル変数をこのファイル内では使用せず、
// siteInfo.jsにまとめることで可読性を上げる
import * as site_info from '../common/functions/siteInfo';
import { initBidManager, initApstag, fetchHeaderBids, getHBTimeoutObject } from '../common/functions/headerBidManager';
import { makeGeoEdgeTag } from  '../common/functions/geoEdge';
import { getUserSegmentIdsLocal } from '../common/functions/piano';

// タイムアウト値のABテスト用オブジェクト
var timeout_abtest_obj = getHBTimeoutObject();
var timeOutObj = {
    failSafe: timeout_abtest_obj['failSafeTimeout'],
    apstagBid: 2000,
};

// GeoEdgeタグ挿入
makeGeoEdgeTag();

// Amazon　A9, Prebid.js load library
var os = site_info.getOS();
var browser = site_info.getBrowser();
var [asa12_mode_v,asadigi_member_type] = site_info.getAsahiMemberType();
var dm_query_params = `?os=${os}&browser=${browser}&key1=${timeout_abtest_obj['prebid_wrapper_key']}`;

// get googletag
var googletag = site_info.getGoogletag();

initBidManager( googletag, undefined, dm_query_params, timeOutObj );
initApstag();

// Initizalize variable
var headerBiddingSlots = [];
var nonHeaderBiddingSlots = [];
var apstagSlots = [];

//セグメント値取得
var goAseg = site_info.getAoneSegment();
// ページ種別（＋一記事の場合は記事ID、その他の場合はページパス）取得
var Pg_Type = 'top';
var Pg_Path = document.location.pathname;
// ブラウザがieかどうか判定（EBDA除外用）
var asaAdBrowserIE = site_info.getAsaAdBrowserIE( browser );
// ChromeのサードパーティCookie廃止テスト期間中のラベル取得
var cookieDeprecationLabel = await site_info.getCookieDeprecationLabel();
// piano用のセグメント取得関数
var segmentIds = getUserSegmentIdsLocal();

function shuffle_list(list) {
    for(var i = list.length - 1; i > 0; i--){
        var rand = Math.floor(Math.random() * (i + 1));
        var value = list[i];
        list[i] = list[rand];
        list[rand] = value;
    }
    return list;
}

//Google Ad Manager定義
if (document.location.href.indexOf('/weather/') == -1) {
    //朝デジトップ

    if (document.readyState === 'loading') {
        // 読み込み中ならDOMContentLoadedで関数を実行
        document.addEventListener('DOMContentLoaded', () => {
            document.getElementById('WebpushAd').style['display'] = 'block';
        });
    } else {
        // そうでなければ即実行
        document.getElementById('WebpushAd').style['display'] = 'block';
    }

    googletag.cmd.push(function () {
        // if (asa12_mode_v === "0" || asa12_mode_v === "1") {
        //     headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/board', [[1, 1], [970, 60], [970, 250], [728, 90], [970, 300]], 'div-gpt-ad-1491727813330-3').addService(googletag.pubads()));            
        // } else {
        //     nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/board', [[1, 1], [970, 60], [970, 250], [970, 300]], 'div-gpt-ad-1491727813330-3').addService(googletag.pubads()));
        // }
        headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/board', [[1, 1], [970, 60], [970, 250], [728, 90], [970, 300]], 'div-gpt-ad-1491727813330-3').addService(googletag.pubads()));
        headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/webpush', [1, 1], 'div-gpt-ad-1573619875004-0').addService(googletag.pubads()));
        headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/1st_rect', [[300, 250], [300, 600]], 'div-gpt-ad-1491727813330-0').addService(googletag.pubads()));
        //headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/2nd_rect', [300, 250], 'div-gpt-ad-1491727813330-1').addService(googletag.pubads()));
        // TOP9本目ネイティブアド誘導枠
        //nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/top9_ad_yudo1', [465, 38],'div-gpt-ad-1550736000285-0').addService(googletag.pubads()));
        // トップパネル
        //nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/top_panel1', [125, 190],'div-gpt-ad-top_panel1').addService(googletag.pubads()));
        // 注目情報
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/article/chumoku11', [[300, 80], 'fluid'],'div-gpt-ad-pc_chumoku11').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/article/chumoku12', [[300, 80], 'fluid'],'div-gpt-ad-pc_chumoku12').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/article/chumoku13', [[300, 80], 'fluid'],'div-gpt-ad-pc_chumoku13').addService(googletag.pubads()));
        // 右ナビPR枠
        //nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/rnavi_asa_pr1', [300, 106],'div-gpt-ad-top_rnavi_asa_pr1').addService(googletag.pubads()));
    
        if (asa12_mode_v === "0") {
            //トップニュース下テキスト広告枠
            var yudo_num_list = shuffle_list([1,2]);
            var div_opts = ['div-gpt-ad-1592468464093-0','div-gpt-ad-1592468464093-1'];
            yudo_num_list.forEach(
                function(num,index){
                    nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/pc_top_infeed_' + num, ['fluid', [1, 1]], div_opts[index]).addService(googletag.pubads()));
                }
            );
            var yudo_num_list2 = shuffle_list([3,4]);
            var div_opts2 = ['div-gpt-ad-1592468589706-0','div-gpt-ad-1592468690725-0'];
            yudo_num_list2.forEach(
                function(num,index){
                    nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/pc_top_infeed_' + num, ['fluid', [1, 1]], div_opts2[index]).addService(googletag.pubads()));
                }
            );                
        }
        //＆、TJAPAN誘導枠
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/pc_top_yudo_andinfeed1', [[1, 1], 'fluid'], 'div-gpt-ad-1592469000688-0').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/pc_top_yudo_andinfeed2', [[1, 1], 'fluid'], 'div-gpt-ad-1592469090706-0').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/pc_top_yudo_tjapan', [[1, 1], 'fluid'], 'div-gpt-ad-1592469154380-0').addService(googletag.pubads()));

        var yudo_num_list = shuffle_list([4,5,6]);
        var div_opts = ['div-gpt-ad-1592468818042-0','div-gpt-ad-1592468870859-0','div-gpt-ad-1592468927032-0'];
        yudo_num_list.forEach(
            function(num,index){
                nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/pc_top_yudo' + num, ['fluid', [1, 1]], div_opts[index]).addService(googletag.pubads()));
            }
        );
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/pc_top_yudo7', ['fluid', [1, 1]], 'div-gpt-ad-1592468731638-0').addService(googletag.pubads()));

        (goAseg) ? googletag.pubads().setTargeting("ASAHISEG", goAseg) : null;
        (asa12_mode_v) ? googletag.pubads().setTargeting("asa12_mode", asa12_mode_v) : null;
        (Pg_Type) ? googletag.pubads().setTargeting("Pg_Type", Pg_Type) : null;
        (Pg_Path) ? googletag.pubads().setTargeting("Pg_Path", Pg_Path) : null;
        (asaAdBrowserIE) ? googletag.pubads().setTargeting("Browser_IE", asaAdBrowserIE) : null;
        (asadigi_member_type) ? googletag.pubads().setTargeting("asadigi_member_type", asadigi_member_type) : null;
        (cookieDeprecationLabel) ? googletag.pubads().setTargeting( "chrome_label" , cookieDeprecationLabel ) : null;
        // piano用k-v
        (segmentIds) ? googletag.pubads().setTargeting("CxSegments" , segmentIds) : null;
        window['sc_asa_digi_prop67'] ? googletag.pubads().setTargeting( 'ab_test_03' , 'top' ) : '';

        // ABテスト用
        // const ab_test_02 = ['softfloor_A','softfloor_B'];
        // googletag.pubads().setTargeting( "ab_test_02" , ab_test_02[Math.floor( Math.random() * ab_test_02.length )] )

        // //朝デジが3コースになり会員情報を細かく識別するため
        // var asahi_cookies = document.cookie.split('; ');
        // var asadigi_member_flag = false;
        // for( var i = 0; i < asahi_cookies.length; i++ ) {
        //     var asahi_cookie = asahi_cookies[i];
        //     if( /WWW_SESS=/.test( asahi_cookie ) ) {
        //         var asadigi_member_type = asahi_cookie.replace( 'WWW_SESS=', '' ).substr( 0, 25 ).replace( /_/g, '' ).replace( /\d\d\d\d\d\d\d\d/, 'YYYYMMDD');
        //         asadigi_member_type && googletag.pubads().setTargeting("asadigi_member_type", asadigi_member_type);
        //         asadigi_member_flag = true;
        //         break;
        //     }
        // }
        // !asadigi_member_flag && googletag.pubads().setTargeting( 'asadigi_member_type', '0' );

        googletag.pubads().disableInitialLoad();
        googletag.pubads().enableSingleRequest();
        googletag.pubads().collapseEmptyDivs();
        googletag.enableServices();

        if (nonHeaderBiddingSlots.length > 0) {
            googletag.pubads().refresh(nonHeaderBiddingSlots);
        }
    });

    // A9 adSlots 定義
    if (asa12_mode_v === "0" || asa12_mode_v === "1") {
        apstagSlots.push({
            slotID: 'div-gpt-ad-1491727813330-3',
            slotName: '/57465213/www.asahi.com/PC/top/board',
            sizes: [[970, 250]]
        });
    }
    apstagSlots.push({
        slotID: 'div-gpt-ad-1491727813330-0',
        slotName: '/57465213/www.asahi.com/PC/top/1st_rect',
        sizes: [[300, 250], [300, 600]]
    });
} else {
    //天気トップ
    googletag.cmd.push(function () {
        // if (asa12_mode_v === "0" || asa12_mode_v === "1") {
        //     headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/article/board', [[1, 1], [970, 60], [970, 250], [728, 90], [970, 300]], 'div-gpt-ad-1581308123776-0').addService(googletag.pubads()));
        //     headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/webpush', [1, 1], 'div-gpt-ad-1573619875004-0').addService(googletag.pubads()));
        // }
        headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/article/board', [[1, 1], [970, 60], [970, 250], [728, 90], [970, 300]], 'div-gpt-ad-1581308123776-0').addService(googletag.pubads()));
        headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/webpush', [1, 1], 'div-gpt-ad-1573619875004-0').addService(googletag.pubads()));
        headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/article/1st_rect', [[300, 250], [300, 600]], '1st_rect').addService(googletag.pubads()));
        headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/article/2nd_rect', [300, 250], '2nd_rect').addService(googletag.pubads()));
        // 注目情報
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/article/chumoku11', [300, 80],'div-gpt-ad-pc_chumoku11').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/article/chumoku12', [300, 80],'div-gpt-ad-pc_chumoku12').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/article/chumoku13', [300, 80],'div-gpt-ad-pc_chumoku13').addService(googletag.pubads()));
        // PR情報
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/article/pc_text_1', [660, 27],'div-gpt-ad-pc_text_1').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/article/pc_text_2', [660, 27],'div-gpt-ad-pc_text_2').addService(googletag.pubads()));
        // 右ナビPR枠
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/PC/top/rnavi_asa_pr1', [300, 106],'div-gpt-ad-top_rnavi_asa_pr1').addService(googletag.pubads()));
        //取得した値セット
        (goAseg) ? googletag.pubads().setTargeting("ASAHISEG", goAseg) : null;
        (asa12_mode_v) ? googletag.pubads().setTargeting("asa12_mode", asa12_mode_v) : null;
        (Pg_Type) ? googletag.pubads().setTargeting("Pg_Type", Pg_Type) : null;
        (Pg_Path) ? googletag.pubads().setTargeting("Pg_Path", Pg_Path) : null;
        (asaAdBrowserIE) ? googletag.pubads().setTargeting("Browser_IE", asaAdBrowserIE) : null;
        (asadigi_member_type) ? googletag.pubads().setTargeting("asadigi_member_type", asadigi_member_type) : null;
        (cookieDeprecationLabel) ? googletag.pubads().setTargeting( "chrome_label" , cookieDeprecationLabel ) : null;
        // piano用k-v
        (segmentIds) ? googletag.pubads().setTargeting("CxSegments" , segmentIds) : null;
        window['sc_asa_digi_prop67'] ? googletag.pubads().setTargeting( 'ab_test_03' , 'top' ) : '';

        googletag.pubads().disableInitialLoad();
        googletag.pubads().enableSingleRequest();
        googletag.pubads().collapseEmptyDivs();
        googletag.enableServices();

        if (nonHeaderBiddingSlots.length > 0) {
            googletag.pubads().refresh(nonHeaderBiddingSlots);
        }
    });

    // A9 adSlots 定義
    apstagSlots.push({
        slotID: '1st_rect',
        slotName: '/57465213/www.asahi.com/PC/article/1st_rect',
        sizes: [[300, 250], [300, 600]]
    });
    apstagSlots.push({
        slotID: '2nd_rect',
        slotName: '/57465213/www.asahi.com/PC/article/2nd_rect',
        sizes: [[300, 250]]
    });

}

googletag.cmd.push( function () {
    fetchHeaderBids( apstagSlots, headerBiddingSlots );
} );
