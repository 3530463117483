// export const window = window;
// export const document = window.document;
export const userAgent = window.navigator.userAgent.toLowerCase();
export const href = window.document.location.href;
export const cookie = window.document.cookie;
// export const AD_TOPICS_WORDS = window.AD_TOPICS_WORDS || [];
export const sc_asa_digi_list2 = window.sc_asa_digi_list2 || '';
export const asaPrebidVideoInstremAd = window.asaPrebidVideoInstremAd;
export const sc_asa_digi_prop53 = window.sc_asa_digi_prop53 || '';
export const sc_asa_digi_prop48 = window.sc_asa_digi_prop48 || '';
export const sc_asa_digi_prop40 = window.sc_asa_digi_prop40 || '';
export const asa12_mode = window.asa12_mode || '0';
export const ad_kijinaka = window.ad_kijinaka || '';
export const ENABLE_FREE_AREA_AD = window.ENABLE_FREE_AREA_AD || '';
// export const googletag = window.googletag;
// export const pbjs = window.pbjs;

// this constant is for sending click rate of creative to piano.
window.parent.configId = "67";
