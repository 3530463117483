export const setPianoSegments = function() {
    var cxContextualIds;
    return (googletag, setServices) => {
        window.cX = window.cX || { callQueue: [] };
        if (cxContextualIds) {
            return;
        }
        const timeoutId = setTimeout(() => {
            if (!window.cX.getSegments) {
                setServices('piano');
            }
        }, 1000);
        window.cX.callQueue.push(['invoke', () => {
            window.cX.getSegments("ac61cf57605beae6bd38563685b65989b1b7f4fe", (segments) => {
                googletag.cmd.push(() => {
                    if (segments.length > 0) {
                        cxContextualIds = segments
                            .filter(s => s.type === "contextual")
                            .map(s => s.shortId);
                        googletag.pubads().setTargeting("CxContext", cxContextualIds);
                    }
                    clearTimeout(timeoutId);
                    setServices('piano');
                });
            }, 500);
        }]);
    }
}()

export  const getUserSegmentIdsLocal = () => {
    var segments = [];
    if(typeof window.localStorage === 'object' && typeof window.localStorage.getItem === 'function' && localStorage.getItem("cxSegments") !== null && localStorage.getItem("cxSegments").length>0) {
      segments = localStorage.getItem("cxSegments").split(',');
    }
    return segments;
}
