import { loadPrebid } from '../tags/loadPrebid';
import { amazonA9 } from '../tags/amazonA9';

let googletag = {};
let pageFlag;
let dmQueryParams;
let pbjs;
let apstagPubId = '3548';
let timeOutObj = {
  apstagBid: 2000,
  failSafe: 3750, // Bidder Timeout: 2500 * 1.5
};
let getApstag;

export function initBidManager( initGoogletag, initPageFlag, initDmQueryParams, initTimeoutObj={} ) {
  googletag = initGoogletag;
  pageFlag = initPageFlag;
  dmQueryParams = initDmQueryParams;
  timeOutObj['failSafe'] = initTimeoutObj['failSafe'] ?? timeOutObj['failSafe'];
  timeOutObj['apstagBid'] = initTimeoutObj['apstagBid'] ?? timeOutObj['apstagBid'];
  getApstag = makeApstag();
  pbjs = makePbjs();
}

export function makeApstag() {
  amazonA9();
  // window.apstagを返しても、
  // window.apstagそのものの変更が反映されなくなるので
  // get関数を返すようにして、apstagを使う時に最新の状態する
  return () => { return window.apstag; };
}

export function initApstag() {
  try {
    getApstag().init( {
      pubID: apstagPubId,
      adServer: 'googletag',
      bidTimeout: timeOutObj['apstagBid'],
      ...( pageFlag && { videoAdServer: 'DFP' }),
    } );
  } catch {
    console.log('An error occurred during the initialization of Apstag.')
  }
}

export function makePbjs() {
  loadPrebid( dmQueryParams );
  window.pbjs = window.pbjs || {};
  window.pbjs.que = window.pbjs.que || [];
  return window.pbjs;
}

export function fetchHeaderBids( apstagSlots, headerBiddingSlots, setServices ) {
  const bidders = ['a9', 'prebid'];

  const requestManager = {
    adserverRequestSent: false,
  };

  bidders.forEach( function ( bidder ) {
    requestManager[bidder] = false;
  } );

  const requestBids = () => {
    getApstag().fetchBids(
      {
        slots: apstagSlots,
      },
      bids => {
        if ( pageFlag?.IS.TOKYO_2020 ) {
          for ( const bid of bids ) {
            if ( bid['slotID'] === 'div-gpt-ad-1518060850192-0' && bid['amznbid'].length > 1 ) {
              asaGumGumFlag = 1;
            }
          }
        }
        headerBidderBack('a9');
      }
    );

    googletag.cmd.push(() => {
      pbjs.que.push(() => {
        pbjs.rp.requestBids({
          callback: () => headerBidderBack('prebid'),
          gptSlotObjects: headerBiddingSlots,
        });
      });
    });
  };

  const headerBidderBack = (bidder) => {
    if (requestManager.adserverRequestSent) return;

    if (bidder === 'a9') {
      googletag.cmd.push(() => getApstag().setDisplayBids());
    } else if (bidder === 'prebid') {
      // DO NOTHING
    }

    requestManager[bidder] = true;

    if (allBiddersBack()) {
      sendAdserverRequest();
    }
  };

  const allBiddersBack = () => {
    return bidders.every(bidder => requestManager[bidder]);
  };

  const sendAdserverRequest = () => {
    if (requestManager.adserverRequestSent) return;
    requestManager.adserverRequestSent = true;
    googletag.cmd.push(() => {
      if (setServices && typeof setServices === 'function') {
        setServices('ads', headerBiddingSlots);
      } else {
        googletag.pubads().refresh(headerBiddingSlots);
      }
    });
  };

  requestBids(apstagSlots, headerBiddingSlots);

  window.setTimeout( () => {
    sendAdserverRequest();
  }, timeOutObj['failSafe'] );
}

export function requestVideoBids(Pg_Target_value, Pg_Keyword_value) {
  window.getPrebidInstreamAdUrl = ( callback ) => {
    const descriptionUrl = document.location.href.split( /[?#]/ )[0];
    let asa12_mode = '';
    if ( typeof window.asa12_mode_v !== 'undefined' ) {
      asa12_mode = window.asa12_mode_v;
    }
    let Pg_Target = '';
    if ( typeof Pg_Target_value !== 'undefined' ) {
      Pg_Target = Pg_Target_value;
    }
    let Pg_Keyword = '';
    if ( typeof Pg_Keyword_value !== 'undefined' ) {
      Pg_Keyword = Pg_Keyword_value;
    }

    const defaultVideoAdUrl =
      'https://pubads.g.doubleclick.net/gampad/ads?' +
      'sz=640x360&' +
      'iu=/57465213/www.asahi.com/mobile/video&' +
      'impl=s&' +
      'gdfp_req=1&' +
      'env=vp&' +
      'output=vast&' +
      'unviewed_position_start=1&' +
      'url=[referrer_url]&' +
      'description_url=' +
      descriptionUrl +
      '&' +
      'correlator=[timestamp]&' +
      'cust_params=Pg_Target%3D{pageVariable.Pg_Target_value}  %26Pg_Keyword%3D{pageVariable.Pg_Keyword_value}%26asa12_mode%3D  {pageVariable.asa12_mode_v}';

    let adserverRequestSent = false;
    const myCallback = function ( videoBidResult ) {
      if ( adserverRequestSent ) {
        return;
      }
      adserverRequestSent = true;

      let videoURL;
      if (
        typeof videoBidResult !== 'undefined' &&
        typeof videoBidResult.adTagUrl !== 'undefined'
      ) {
        videoURL = 
          videoBidResult.adTagUrl +
          '%26Pg_Target%3D' +
          Pg_Target +
          '%26Pg_Keyword%3D' +
          Pg_Keyword +
          '%26asa12_mode%3D' +
          asa12_mode +
          '&description_url=' +
          descriptionUrl;
      } else {
        videoURL = defaultVideoAdUrl;
      }
      callback( videoURL );
    };

    pbjs.que.push( function () {
      pbjs.rp.requestVideoBids( {
        adSlotName: '/57465213/www.asahi.com/mobile/video',
        playerSize: [640, 360],
        callback: myCallback,
        data: {},
      } );
    } );

    // function timeout
    window.setTimeout( function () {
      myCallback();
    }, 4500 );
  };
}

export const getHBTimeoutObject = () => {
  var timeout_array = [3750];
  // var timeout_array_index = Math.floor( Math.random() * timeout_array.length );
  var timeout_array_index = 0;
  return {
      failSafeTimeout: timeout_array[timeout_array_index],
      prebid_wrapper_key: 'failSafeTimeout' + timeout_array[timeout_array_index] + 'ms'
  };
}

export const setID5 = () => {
  window.ID5EspConfig = {
      partnerId: 649
  };
}
